<template>
    <a-modal :width="600" :visible="visible" @cancel="handleCancel" title="复制实验模板">
        <a-spin :spinning="loading" tip="正在加载中..." :delay="300">
            <a-form-model ref="formRef" :model="formData" style="width: 95%;margin: 0 auto;" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 6 },
            }" :wrapper-col="{ span: 18 }" :rules="rules">
                <a-form-model-item label="模板名称" prop="name">
                    <a-input v-model="formData.name" placeholder="请输入"></a-input>
                </a-form-model-item>
                <a-form-model-item label="实验类型" prop="experimentType">
                    <a-select placeholder="请选择实验类型" v-model="formData.experimentType" allowClear style="width: 100%;">
						<a-select-option v-for="(item,index) in dataList" :value="item.value" :key="index" >
							{{ item.text }}
						</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="行业领域" prop="industrySector">
                    <a-cascader
						allowClear
						v-model="formData.industrySector"
						:field-names="{ label: 'name', value: 'id', children: 'children' }"
						:options="industrySectorList"
						placeholder="请选择行业领域"
						style="width: 100%;"
					/>
                </a-form-model-item>
                <a-form-model-item label="描述" prop="description">
                    <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.description" placeholder="请输入">
                    </a-textarea>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
         <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button :disabled="loading" type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { ref, watch } from 'vue-demi';
import {  getExperimentTemplateInitData, getTemplateDetail, addExperimentTemplate } from '../../../../api/experimentTemplate';

export default {
    props: {
        id: {
            type: String | Number | null,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const loading = ref(false);
        const formData = ref({
            name: '',
            description: '',
            content: '',
            experimentType: undefined,
            industrySector: []
        });
        const formRef = ref(null);
        const industrySectorList = ref([]);
        const dataList = ref([]);
        // 初始化
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                loading.value = true;
                formRef.value && formRef.value.resetFields();
                const initRes = await getExperimentTemplateInitData();
                if(initRes.code === 200) {
                    industrySectorList.value = initRes.data.industrySectorList;
                    dataList.value = initRes.data.dataList;
                }
                const detailRes = await getTemplateDetail({
                    Id: props.id
                });
                if(detailRes.code === 200) {
                    formData.value.content = detailRes.data.content;
                    formData.value.name = `${detailRes.data.name}-副本`;
                    formData.value.description = detailRes.data.description;
                    formData.value.industrySector = detailRes.data.industrySector ? detailRes.data.industrySector.split(',') : [];
                    formData.value.projectId = detailRes.data.projectId;
                    for(let i = 0; i < formData.value.industrySector.length; ++i) {
                        formData.value.industrySector[i] = parseInt(formData.value.industrySector[i])
                    }
                    formData.value.experimentType = detailRes.data.experimentType
                    loading.value = false
                }
            }
        });
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async(valid) => {
                if(valid) {
                    const submitData = JSON.parse(JSON.stringify(formData.value));
                    submitData.industrySector = submitData.industrySector.join(',');
                    const res = await addExperimentTemplate(submitData);
                    if(res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit('onCloneTemplate')
                        context.emit('update:visible',false);
                    }
                }
            })
        }
        const rules = ref({
            name: [{
                required: true,
                message: '请输入'
            },
            {
                max: 50,
                message: '不能超过50个字符'
        }],
            experimentType: [{
                required: true,
                message: '请选择'
            }],
            industrySector: [{
                required: true,
                message: '请选择'
            }],
            description: [{
                max: 200,
                message: '不能超过200个字符'
            }]
        })
        return {
            handleCancel,
            loading,
            formData,
            formRef,
            industrySectorList,
            dataList,
            handleConfirm,
            rules
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    .concel-btn {
        background: #eeeeee;
    }
}
</style>