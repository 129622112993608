<template>
	<div class="sTable">
		<!-- <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);margin-top: 50px;">
			<p style="color: #1890FF;font-size: 16px;">55555</p>
		</div> -->
		<a-table :rowKey="(record, index) => index" :rowSelection="rowSelection" :bordered="bordered" position="both"
			@change="changePagination" :pagination="pagination" :scroll="{ y: rollHeight }" :columns="columns"
			:data-source="data" :loading="loading">
			<!-- <span slot="name" slot-scope="item,record" v-for="item in columns">
				<slot name="name" :record="record"></slot>
			</span> -->
			<template v-for="(index, name) in $slots" :slot="name">
				<slot :name="name" />
			</template>
			<template v-for="(index, name) in $scopedSlots" v-slot:[name]="slotProps, record, index">
				<slot :name="name" v-bind="{ slotProps, record, index }"></slot>
			</template>
		</a-table>
	</div>
</template>

<script>
export default {
	props: ['columns', 'data', 'rollHeight', 'pagination', 'bordered', 'rowSelection', 'loading'],
	// columns列值 data表格数据 rollHeight表格滚动高度 pagination分页配置项 bordered是否显示边框 rowSelection勾选项配置
	data() {
		return {

		}
	},
	methods: {
		changePagination(data) {
			this.$emit('change', data)
		}
	}
}
</script>

<style>
.sTable .ant-table-column-title {
	overflow: visible !important;
}
</style>

<style lang="less" scoped>
.sTable {
	/deep/ .ant-table-column-title {
		overflow: visible !important;
	}

	/deep/ .ant-pagination {
		float: none !important;
		margin-top: 32px;
	}

	/deep/ .ant-pagination-item-active {
		background: @srims-primary-color;
		border-color: @srims-primary-color;

		a {
			color: white !important;
		}
	}

	/deep/ .ant-pagination-item {
		a:hover {
			border-color: @srims-primary-color;
			color: @srims-primary-color;
		}
	}

	/deep/ .ant-pagination-options-quick-jumper {
		input:focus {
			border-color: @srims-primary-color;
		}
	}

	/deep/ .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
		color: @srims-primary-color;
		fill: @srims-primary-color;
	}

	/deep/ .ant-table-thead th {
		background: #F0F5FF;
	}

	/deep/ .ant-table>.ant-table-content>.ant-table-body {
		margin: 0px;
	}

	/deep/ .ant-table-row {
		td {
			//padding: 10px;
		}
	}

	/deep/ .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
		padding: 10px;
	}
}</style>