<template>
	<div>
		<div class="title">
			我的模板
		</div>
		<div class="search-panel">
			<a-row :gutter="16">
				<a-col :span="5">
					<a-input placeholder="模板名称/模板描述" v-model="name" allowClear></a-input>
				</a-col>
				<a-col :span="5">
					<a-cascader allowClear v-model="industryArr"
						:field-names="{ label: 'name', value: 'id', children: 'children' }"
						:options="industrySectorList" placeholder="请选择行业领域" style="width: 100%;" />
				</a-col>
				<a-col :span="5">
					<a-select placeholder="请选择实验类型" v-model="experimentType" allowClear style="width: 100%;">
						<a-select-option v-for="(item, index) in dataList" :value="item.value" :key="index">
							{{ item.text }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="5">
					<a-range-picker style="width: 100%;" valueFormat="YYYY-MM-DD" v-model="dateRange" />
				</a-col>
				<a-col :span="4">
					<a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
					<a-button class="reset-btn" @click="resetSearch"> 重置 </a-button>
				</a-col>
			</a-row>
			<div style="margin-top: 12px;text-align: left;">
				<a-button @click="handleGoToAddNewTemplate">
					+ 新建模板
				</a-button>
			</div>
		</div>
		<a-spin :spinning="loading" tip="正在加载中...">
			<sTable :columns="columns" :data="data" :pagination.sync="pagination" @change="change"
				:rollHeight="tableHeight">
				<template slot="name" slot-scope="data">
					<a-tooltip placement="topLeft">
						<template slot="title">
							<span>{{ data.record.name }}</span>
						</template>
						<div style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
							{{ data.record.name }}
						</div>	
					</a-tooltip>
				</template>
				<template slot="description" slot-scope="data">
					<a-tooltip placement="topLeft">
						<template slot="title">
							<span>{{ data.record.description }}</span>
						</template>
						<div style="width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
							{{ data.record.description }}
						</div>	
					</a-tooltip>
				</template>
				<template slot="operation" slot-scope="data">
					<a @click="handleCloneTemplateClick(data.record)"> 复制 </a>
					<a style="margin-left: 16px;" v-show="data.record.editPermission" :href="getEditUrl(data.record)" target="_blank" > 编辑 </a>
					<a style="margin-left: 16px;" :href="getPreviewUrl(data.record)" target="_blank"> 预览 </a>
					<a style="margin-left: 16px;" @click="handleShareTemplate(data.record)" v-if="data.record.sharePermission"> 分享 </a>
					<a style="margin-left: 16px;" class="delete-btn" @click="handleDelete(data.record)" v-if="data.record.deletePermission"> 删除 </a>
				</template>
			</sTable>
		</a-spin>
		<CloneExperimentTemplate :visible.sync="cloneExperimentTemplateVisible" :id="cloneTemplate.id"
			@onCloneTemplate="onCloneTemplate"></CloneExperimentTemplate>
	</div>
</template>

<script>
import { message, Modal } from 'ant-design-vue'
import { getExperimentTemplateInitData, getMyTemplateList, deleteTemplate, shareExperimentTemplate } from '../../../api/experimentTemplate'
import sTable from '../../../components/sTable.vue';
import CloneExperimentTemplate from './components/cloneExperimentTemplate.vue';
const tableHeight = document.documentElement.clientHeight - 480;
export default {
	components: {
		sTable,
		CloneExperimentTemplate
	},
	data() {
		return {
			columns: [{
				title: '实验模板名称',
				dataIndex: 'name',
				key: 'name',
				slots: {
					title: 'customTitle'
				},
				scopedSlots: {
					customRender: 'name'
				},
			},
			{
				title: '来源',
				dataIndex: 'source'
			},
			{
				title: '作者',
				dataIndex: 'authorName'
			},
			/* {
				title: '创建时间',
				dataIndex: ''
			}, */
			{
				title: '修改时间',
				dataIndex: 'updatedTime'
			},
			{
				title: '行业领域',
				dataIndex: 'industrySectorName'
			},
			{
				title: '实验类型',
				dataIndex: 'experimentTypeName'
			},
			{
				title: '模板描述',
				dataIndex: 'description',
				scopedSlots: {
					customRender: 'description'
				},
			},
			{
				title: '操作',
				dataIndex: 'operation',
				key: 'operation',
				width: 260,
				fixed: "right",
				scopedSlots: { customRender: "operation" }
			}
			],
			data: [

			],
			pagination: {
				total: 0,
				defaultPageSize: 10,
				showTotal: total => `共 ${total} 条数据`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
				current: 1,
				pageSize: 10
			},
			industrySectorList: [],
			dataList: [],
			name: undefined,	// 模板名称/模板描述
			industryArr: [], // 选择的行业领域
			experimentType: undefined, 	// 实验类型
			dateRange: [],	// 修改时间
			tableHeight,
			cloneExperimentTemplateVisible: false,
			cloneTemplate: {},
			loading: false
		}
	},
	methods: {
		change(data) {
			this.pagination.current = data.current;
			this.pagination.pageSize = data.pageSize;
			this.getMyTemplate();
		},
		handleSearch() {
			this.getMyTemplate();
		},
		resetSearch() {
			this.name = '',
				this.experimentType = undefined,
				this.industryArr = [],
				this.dateRange = []
		},
		async getMyTemplate() {
			this.loading = true;
			const res = await getMyTemplateList({
				Name: this.name,
				ExperimentType: this.experimentType ? this.experimentType : undefined,
				IndustrySector: this.industryArr.length > 0 ? this.industryArr.join(',') : undefined,
				PageNo: this.pagination.current,
				pageSize: this.pagination.pageSize,
				SearchBeginTime: this.dateRange.length == 2 ? this.dateRange[0] : undefined,
				SearchEndTime: this.dateRange.length == 2 ? this.dateRange[1] : undefined
			});
			if (res.code === 200) {
				this.data = res.data.rows;
				this.pagination.total = res.data.totalRows;
				this.loading = false;
			}
		},
		handleDelete(template) {
			Modal.confirm({
				title: '提示',
				content: '是否确认删除该模板？',
				onOk: async () => {
					const res = await deleteTemplate({
						id: template.id
					});
					if (res.code === 200 || res.code == 204) {
						message.success(res.message);
						this.getMyTemplate();
					}
				}
			})
		},
		handleCloneTemplateClick(template) {
			this.cloneTemplate = template;
			this.cloneExperimentTemplateVisible = true;
		},
		onCloneTemplate() {
			this.getMyTemplate();
		},
		handleShareTemplate(template) {
			Modal.confirm({
				title: '提示',
				content: '是否确认将该模板分享至模板中心？',
				onOk: async () => {
					const res = await shareExperimentTemplate({
						Id: template.id
					});
					if (res.code === 200 || res.code === 204) {
						message.success(res.message);
					}
				}
			})
		},
		getPreviewUrl(template) {
			const href = this.$router.resolve({
				name: 'experimentTemplate',
				query: {
					id: template.id,
					mode: 'preview'
				}
			})
			return href.href;
		},
		getEditUrl(template) {
			const href = this.$router.resolve({
				name: 'experimentTemplate',
				query: {
					id: template.id,
					mode: 'edit'
				}
			})
			return href.href;
		},
		handleGoToAddNewTemplate() {
			const href = this.$router.resolve({ name: 'newExperimentTemplate'});
			window.open(href.href, '_blank')
		}
	},
	async mounted() {
		const initRes = await getExperimentTemplateInitData();
		if (initRes.code === 200) {
			this.industrySectorList = initRes.data.industrySectorList;
			this.dataList = initRes.data.dataList;
		}
		this.getMyTemplate();
	}
}
</script>

<style lang="less" scoped>
.search-panel {
	padding: 0px 16px;
	margin-bottom: 16px;
}



.reset-btn {
	background: #eeeeee;
	margin-left: 8px;
}

.delete-btn {
	color: #FF4D4F;

	&:hover {
		opacity: 0.6;
	}
}

/deep/ .sTable .ant-table {
	min-height: calc(100vh - 520px);
}
/deep/ .sTable .ant-table-thead {
	line-height: 1;
}
/deep/ .ant-table-thead > tr > th {
	//padding: 10px 16px;
}
/deep/ .ant-table-fixed-header .ant-table-body-inner {
    overflow: auto;
}
</style>

<style scoped>
.title {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.85);
	text-align: left;
	border-bottom: 1px solid #EBEBEB;
	margin: 0 16px;
	padding: 16px 0;
	margin-bottom: 16px;
	font-weight: 550
}
</style>
