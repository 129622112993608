import { render, staticRenderFns } from "./sTable.vue?vue&type=template&id=3b7ed45a&scoped=true&"
import script from "./sTable.vue?vue&type=script&lang=js&"
export * from "./sTable.vue?vue&type=script&lang=js&"
import style0 from "./sTable.vue?vue&type=style&index=0&lang=css&"
import style1 from "./sTable.vue?vue&type=style&index=1&id=3b7ed45a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7ed45a",
  null
  
)

export default component.exports